import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TargetContent from "src/components/targetcontent"
import CancelModal from "src/components/cancelmodal"
import FooterLinks from "src/components/footerlinks"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

function Cta(props) {
    return <button data-cy="cta" onClick = {() => window.triggerInstall()}><span>Add to {props.browser}. Its Free.</span></button>;
}

export default function prdbest() {
    let module = null;
    if(isFirefox()){
        module = <Cta browser="Firefox"></Cta>;
    }
    if(isChrome()){
        module = <Cta browser="Chrome"></Cta>;
    }
    if(isIeEdge()){
        module = <Cta browser="Edge"></Cta>;
    }

  return(
    <HomepageLayout>

    <Helmet>
      <style type="text/css">{`
      `}
      </style>
      <title>The genius trick every Target shopper should know</title>
    </Helmet>

    <section>
      <TargetContent data={prdbestData}>{module}</TargetContent>
      <CancelModal></CancelModal>

      <FooterLinks></FooterLinks>

    </section>

    </HomepageLayout>
  )
}
