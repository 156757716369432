import React from "react"

import HomepageData from "./data/extendedhomepage.json"
import Styles from "./css/extendedhomepage.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class ExtendedHomepage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: HomepageData,
        browser: ''
    }
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
      this.state.browser = this.props.children.props.browser;
      if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
    });
  }

  render() {
    return (
      <div className = {Styles.extendedhomepage}>

      <div className = {Styles.header}>
            <div className = {Styles.headerwrapper}>
              <div className = {Styles.logodiv}>
                <p className={Styles.ftcDisclosure}>This post is sponsored by Capital One Shopping</p>
                <img className = {Styles.toplogo} src={this.state.data.logo}/>
              </div>

              <button data-cy="cta" onClick = {() => window.triggerInstall()}><span>Add to {this.state.browser}.</span> It's Free.</button>
            </div>
      </div>

      <div className = {Styles.allContainer}>

        <h2>The genius trick every Target shopper should know</h2>
        <p className = {Styles.date}>September 18, 2022</p>
        <img src='/assets/sg_target.jpeg'/>


        <h3>You might as well be throwing money away if you’re not using <a class="cta_href" href='javascript:void(0)' onClick = {() => window.triggerInstall()}>Capital One Shopping</a>. This Chrome extension knows nearly every trick in the frugal bible and will automatically try them for you (and the 3,000,000+ other people currently using it).</h3>
        <h3>Here’s how it works:</h3>

        <p>
        <img src='/assets/blog_target1.png'/>

        </p>

        <h3>Shop at Target, Best Buy, Home Depot, Adidas or anywhere else? When you checkout, <a class="cta_href" href='javascript:void(0)' onClick = {() => window.triggerInstall()}>Capital One Shopping</a> will instantly find working coupon codes and discounts and apply them to your order.</h3>

        <h3>Ever shop on Amazon? Capital One Shopping automatically checks other sellers for a better price, including sales tax + shipping. A notification will appear in seconds if a better offer is found.</h3>

        <p>
        <img src='/assets/blog_target2.png'/>

        </p>

        <h3>The best part? It’s totally free. Do yourself (and your wallet) a favor and get Capital One Shopping <a class="cta_href" href='javascript:void(0)' onClick = {() => window.triggerInstall()}>HERE</a>.</h3>

        <div className = {Styles.footcta}>
          <div className = {Styles.btnname}>
          <button data-cy="cta" onClick = {() => window.triggerInstall()}><span>Add to {this.state.browser}.</span> It's Free.</button></div>

          <div className = {Styles.rating}>
          <div className = {Styles.ratinglabel}>6,000,000+ users, Chrome Web Store</div>

          <div className = {Styles.ratingstars}>

            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            </div>
            </div>
            <span></span>

        </div>

      </div> {/*end allContainer*/}

      <div className = {Styles.footer}>
        <div className = {Styles.foottitle}><strong>As reviewed by</strong></div>

        <div className = {Styles.footcompany}>
          <img width='100px' height='26px' src='/assets/forbes.png' onClick={() => window.open('https://www.forbes.com/advisor/banking/money-saving-apps-and-browser-extensions-for-online-shopping/')}/>
          <img width='122px' height='26px' src='/assets/fortune.png' onClick={() => window.open('https://fortune.com/2022/05/26/ways-to-protect-your-money-from-inflation/')}/>
          <img width='76px' height='30px' src='/assets/usatoday.png' onClick={() => window.open('https://www.usatoday.com/story/tech/talkingtech/2019/02/28/wikibuy-google-bing-searches-can-help-you-save-amazon-purchases/3017747002/')}/>
          <img width='117px' height='20px' src='/assets/mashable.png' onClick={() => window.open('https://mashable.com/deals/may-17-capital-one-shopping')}/>
        </div>
      </div>

    </div>



    )
  }
}
export default ExtendedHomepage
